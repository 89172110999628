/**
 * Note: This file has been copied over from Shop-SDK.
 *
 * We might move this to lib-order in the future.
 */

/* eslint max-len: 0 */
import Validator from 'validatorjs';

// @ts-expect-error - validatorjs' global state requires some magic. See comments below
import ca from 'validatorjs/src/lang/ca';
// @ts-expect-error - validatorjs' global state requires some magic. See comments below
import cs from 'validatorjs/src/lang/cs';
// @ts-expect-error - validatorjs' global state requires some magic. See comments below
import da from 'validatorjs/src/lang/da';
// @ts-expect-error - validatorjs' global state requires some magic. See comments below
import de from 'validatorjs/src/lang/de';
// @ts-expect-error - validatorjs' global state requires some magic. See comments below
import el from 'validatorjs/src/lang/el';
// @ts-expect-error - validatorjs' global state requires some magic. See comments below
import en from 'validatorjs/src/lang/en';
// @ts-expect-error - validatorjs' global state requires some magic. See comments below
import es from 'validatorjs/src/lang/es';
// @ts-expect-error - validatorjs' global state requires some magic. See comments below
import fr from 'validatorjs/src/lang/fr';
// @ts-expect-error - validatorjs' global state requires some magic. See comments below
import it from 'validatorjs/src/lang/it';
// @ts-expect-error - validatorjs' global state requires some magic. See comments below
import nl from 'validatorjs/src/lang/nl';
// @ts-expect-error - validatorjs' global state requires some magic. See comments below
import pt from 'validatorjs/src/lang/pt';
// @ts-expect-error - validatorjs' global state requires some magic. See comments below
import tr from 'validatorjs/src/lang/tr';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

// It is not possible to dynamically require language files when a package that has validatorjs as a dependency
// is compiled using rollup. Validatorjs does this internally.
// When this package is used in conjunction with a rollup compiled package, due to the global nature of
// a lot of features within validatorjs, this fails!
// Initially only loaded 'en' here, as it is the default language. This is also referenced internally
// for some methods that are not directly related to validation errors.
// However, some languages were missing the 'def' translation, so just added all main locales here.

Validator.setMessages('ca', {
    def: "L'atribut :attribute tÃ© errors.",
    attributes: {},
    ...((ca as Validator.ErrorMessages) || {}),
});
Validator.setMessages('cs', {
    def: 'Atribut :attribute obsahuje chyby.',
    attributes: {},
    ...((cs as Validator.ErrorMessages) || {}),
});
Validator.setMessages('da', {
    def: ':attribute attributen har fejl.',
    attributes: {},
    ...((da as Validator.ErrorMessages) || {}),
});
Validator.setMessages('de', {
    def: 'Das :attribute Feld hat Fehler.',
    attributes: {},
    ...((de as Validator.ErrorMessages) || {}),
});
Validator.setMessages('el', {
    def: 'Î¤Î¿ Ï€ÎµÎ´Î¯Î¿ :attribute Ï€ÎµÏÎ¹Î­Ï‡ÎµÎ¹ ÏƒÏ†Î¬Î»Î¼Î±Ï„Î±.',
    attributes: {},
    ...((el as Validator.ErrorMessages) || {}),
});
Validator.setMessages('en', {
    def: 'The :attribute attribute has errors.',
    attributes: {},
    ...((en as Validator.ErrorMessages) || {}),
});
Validator.setMessages('es', {
    def: 'El atributo :attribute tiene errores.',
    attributes: {},
    ...((es as Validator.ErrorMessages) || {}),
});
Validator.setMessages('fr', {
    def: 'Le champ :attribute contient un attribut erronÃ©.',
    attributes: {},
    ...((fr as Validator.ErrorMessages) || {}),
});
Validator.setMessages('it', {
    def: "L'attributo :attribute contiene errori.",
    attributes: {},
    ...((it as Validator.ErrorMessages) || {}),
});
Validator.setMessages('nl', {
    def: 'Het :attribute veld bevat fouten.',
    attributes: {},
    ...((nl as Validator.ErrorMessages) || {}),
});
Validator.setMessages('pt', {
    def: 'O atributo :attribute contÃ©m erros.',
    attributes: {},
    ...((pt as Validator.ErrorMessages) || {}),
});
Validator.setMessages('tr', {
    def: ':attribute hatalar iÃ§eriyor.',
    attributes: {},
    ...((tr as Validator.ErrorMessages) || {}),
});

// https://docs.microsoft.com/en-us/office/troubleshoot/excel/determine-a-leap-year
function leapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

function checkFalsePositiveDates(dateString = '') {
    if (dateString.length === 10) {
        // massage input to use yyyy-mm-dd format
        // we support yyyy/mm/dd or yyyy.mm.dd
        const normalizedDate = dateString.replace('.', '-').replace('/', '-');
        const parts = normalizedDate.split('-');

        if (parts.length === 3) {
            if (parts[0].length === 4) {
                // yyyy-mm-dd format
                const y = parseInt(parts[0], 10);
                const m = parseInt(parts[1], 10);
                const d = parseInt(parts[2], 10);

                if (m === 2) {
                    // return leapYear(y) ? d <= 29 : d <= 28;
                    if (leapYear(y)) {
                        if (d > 29) {
                            return false;
                        }
                    } else if (d > 28) {
                        return false;
                    }
                }

                if (m === 4 || m === 6 || m === 9 || m === 11) {
                    if (d > 30) {
                        return false;
                    }
                }
            }
        }

        return true; // we are not in february, proceed
    }

    return true; // we are not testing formatted date, proceed to rest of validation
}

function isValidDate(dateString: unknown): dateString is string | number {
    let testDate;

    if (typeof dateString === 'number') {
        testDate = new Date(dateString);

        if (typeof testDate === 'object') {
            return true;
        }

        dateString = `${dateString}`;
    }

    if (typeof dateString !== 'string') {
        return false;
    }

    // first convert incoming string to date object and see if it corrects date and format
    testDate = new Date(dateString);

    if (typeof testDate === 'object') {
        if (testDate.toString() === 'Invalid Date') {
            return false;
        }

        /**
         * Check for false positive dates
         * perform special check on february as JS `new Date` incorrectly returns valid date
         * E.g.  let newDate = new Date('2020-02-29')  // returns as March 02, 2020.
         * E.g.  let newDate = new Date('2019-02-29')  // returns as March 01, 2020.
         * E.g.  let newDate = new Date('2019-04-31')  // returns as April 30, 2020.
         */
        if (!checkFalsePositiveDates(dateString)) {
            return false;
        }

        // valid date object and not a february date
        return true;
    }

    // First check for the pattern
    const regex_date = /^\d{4}-\d{1,2}-\d{1,2}$/;

    if (!regex_date.test(dateString)) {
        return false;
    }

    // Parse the date parts to integers
    const parts = dateString.split('-');
    const day = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[0], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12) {
        return false;
    }

    const monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
}

interface Rule {
    name: string;
    fn: (val: unknown, req: string, attribute: string) => boolean;
    validator: Validator.Validator<Record<string, string | number | boolean>>;
}

function validateAndParseDates(
    inputValue: unknown,
    ruleValue: unknown,
    rule: Rule,
): null | { inputValue: Date; ruleValue: Date } {
    if (!isValidDate(inputValue)) {
        return null;
    }

    if (!isValidDate(ruleValue)) {
        if (typeof ruleValue !== 'string' && typeof ruleValue !== 'number') {
            return null;
        }

        if (
            typeof ruleValue === 'string'
            && [ 'yesterday', 'today', 'tomorrow' ].includes(ruleValue)
        ) {
            // Using Date.now for testability.
            const relativeReferenceDate = new Date(Date.now());

            // The reference dates are always set to 'today' 00:00 with the below
            // offsets.
            //
            // | rule            | yesterday | today     | tomorrow  |
            // | --------------- | --------- | --------- | --------- |
            // | before          | -1d ;  0s |  0d ;  0s | +1d ;  0s |
            // | before_or_equal |  0d ; -1s | +1d ; -1s | +2d ; -1s |
            // | after           |  0d ; -1s | +1d ; -1s | +2d ; -1s |
            // | after_or_equal  | -1d ;  0s |  0d ;  0s |  1d ;  0s |

            const shouldIncrementDateAndSubtractSecond = [
                'before_or_equal',
                'after',
            ].includes(rule.name)
                ? 1
                : 0;

            const relativeDayOffset: { [key: string]: number } = {
                yesterday: -1 + shouldIncrementDateAndSubtractSecond,
                today: 0 + shouldIncrementDateAndSubtractSecond,
                tomorrow: 1 + shouldIncrementDateAndSubtractSecond,
            };

            if (ruleValue in relativeDayOffset) {
                relativeReferenceDate.setDate(
                    relativeReferenceDate.getDate()
                    + relativeDayOffset[ruleValue],
                );
                relativeReferenceDate.setHours(
                    0,
                    0,
                    0,
                    -1 * shouldIncrementDateAndSubtractSecond,
                );
            }

            rule.validator.setAttributeFormatter((attribute: unknown) => {
                if (attribute === ruleValue) {
                    return [
                        relativeReferenceDate.getFullYear(),
                        (relativeReferenceDate.getMonth() + 1)
                            .toFixed(0)
                            .padStart(2, '0'),
                        relativeReferenceDate
                            .getDate()
                            .toFixed(0)
                            .padStart(2, '0'),
                    ].join('-');
                }

                return attribute;
            });

            return {
                inputValue: new Date(inputValue),
                ruleValue: new Date(relativeReferenceDate),
            };
        }

        // The value is a string, but not a valid date and not a supported relative date.
        // This will lookup if the value is a reference to another input property and
        // verify it's value.
        const referencedRuleValue: string | number | boolean = rule.validator.input[ruleValue];

        if (!isValidDate(referencedRuleValue)) {
            return null;
        }

        return {
            inputValue: new Date(inputValue),
            ruleValue: new Date(referencedRuleValue),
        };
    }

    return {
        inputValue: new Date(inputValue),
        ruleValue: new Date(ruleValue),
    };
}

function after(this: Rule, val: string | number | boolean, req: string) {
    const parsed = validateAndParseDates(val, req, this);

    if (!parsed) {
        return false;
    }

    return parsed.ruleValue.getTime() < parsed.inputValue.getTime();
}

function after_or_equal(
    this: Rule,
    val: string | number | boolean,
    req: string,
) {
    const parsed = validateAndParseDates(val, req, this);

    if (!parsed) {
        return false;
    }

    return parsed.ruleValue.getTime() <= parsed.inputValue.getTime();
}

function before(this: Rule, val: string | number | boolean, req: string) {
    const parsed = validateAndParseDates(val, req, this);

    if (!parsed) {
        return false;
    }

    return parsed.ruleValue.getTime() > parsed.inputValue.getTime();
}

function before_or_equal(
    this: Rule,
    val: string | number | boolean,
    req: string,
) {
    const parsed = validateAndParseDates(val, req, this);

    if (!parsed) {
        return false;
    }

    return parsed.ruleValue.getTime() >= parsed.inputValue.getTime();
}

const originalEmailRule: Rule['fn'] = ((new Validator({}, {}).getRule(
    'email',
) as unknown) as Rule).fn;

function email(
    this: Rule,
    val: string | number | boolean,
    req: string,
    attribute: string,
) {
    // The builtin email rule is not strict enough, we only allow ascii

    if (typeof val !== 'string') {
        return false;
    }

    // First validate using the builtin rule (at time of writing).
    // Anything not conforming to that format should fail regardless.
    if (!originalEmailRule(val, req, attribute)) {
        return false;
    }

    // Second ensure only (visible) ascii characters are present.
    // The build-in rule has been extensively verified,
    // the separation in these 2 implementations -
    // while technically a bit less efficient - allows the adaption
    // to be as simple and straightforward as possible.
    return /^[ -~]*$/.test(val);
}

function phone(
    this: Rule,
    val: string | number | boolean,
    _req: string,
    _attribute: string,
) {
    if (typeof val !== 'string') {
        return false;
    }

    return isValidPhoneNumber(val);
}

function phone_possible(
    this: Rule,
    val: string | number | boolean,
    _req: string,
    _attribute: string,
) {
    if (typeof val !== 'string') {
        return false;
    }

    return isPossiblePhoneNumber(val);
}

function distinct(
    this: Rule,
    _val: string | number | boolean,
    _req: string,
    _attribute: string,
): boolean {
    // Rule is not implemented... Ignore!
    //
    // The rule is a valid rule in Laravel.
    //
    // Currently in the OSP, the rule key (distinct) is used to do a custom check on
    // ticket metadata values being unique within the order.
    return true;
}

const messages: Validator.ErrorMessages = Validator.getMessages(
    Validator.getDefaultLang(),
);

Validator.register(
    'after',
    after,
    messages && typeof messages.after === 'string' ? messages.after : undefined,
);
Validator.register(
    'after_or_equal',
    after_or_equal,
    messages && typeof messages.after_or_equal === 'string'
        ? messages.after_or_equal
        : undefined,
);
Validator.register(
    'before',
    before,
    messages && typeof messages.before === 'string'
        ? messages.before
        : undefined,
);
Validator.register(
    'before_or_equal',
    before_or_equal,
    messages && typeof messages.before_or_equal === 'string'
        ? messages.before_or_equal
        : undefined,
);
Validator.register(
    'email',
    email,
    messages && typeof messages.email === 'string' ? messages.email : undefined,
);

Validator.register(
    'phone',
    phone,
    messages && typeof messages.phone === 'string' ? messages.phone : undefined,
);

Validator.register(
    'phone_possible',
    phone_possible,
    messages && typeof messages.phone_possible === 'string'
        ? messages.phone_possible
        : undefined,
);

Validator.register(
    'distinct',
    distinct,
    messages && typeof messages.distinct === 'string'
        ? messages.distinct
        : undefined,
);

export { Validator };
