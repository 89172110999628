<template>
    <div
        class="order-ticket"
        :class="{
            'has-status': hasStatus,
            'actionable-by-visitor': needsPersonalization,
            'in-progress': refundInProgress || isRendering,
        }"
    >
        <OrderItem
            :title="ticket.ticket.name"
            :count-title="countTitle"
            :subtitle="personalizedTitle"
            :opened="opened"
            :locked="locked"
            :invalidated-since="ticket.invalidated_since"
            :personalization-complete="personalizationComplete"
            :needs-personalization="needsPersonalization"
            :has-content="hasContent"
            :refund-in-progress="refundInProgress"
            :is-invalid="isInvalid"
            :is-cancelled="isCancelled"
            :is-resold="isResold"
            :is-sealed="isSealed"
            :seat-label="seatLabel"
            :simplified-status="simplifiedStatus"
            :wallet-enabled="walletEnabled"
            @toggle="$emit('toggle')"
        >
            <template #actions>
                <a
                    v-if="isDownloadable && ticket.download_link"
                    :href="ticket.download_link"
                    target="_blank"
                >
                    <div
                        class="
                            order-item__heading__action
                            order-ticket__download
                        "
                    >
                        <i class="oti oti-download" />
                        <!-- Todo: replace this label with a more sensible element -->
                        <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                        <label>{{
                            $t('order.components.order_ticket.buttons.download')
                        }}</label>
                    </div>
                </a>
                <div
                    v-else-if="isRendering"
                    class="order-item__heading__action order-ticket__rendering"
                >
                    <i class="oti oti-spinner" />
                    <!-- Todo: replace this label with a more sensible element -->
                    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                    <label class="order-ticket__rendering__label">{{
                        $t('order.components.order_ticket.buttons.rendering')
                    }}</label>
                </div>
                <div
                    v-else-if="walletEnabled"
                    class="order-item__heading__action"
                >
                    <i class="oti oti-call" />
                    <!-- Todo: replace this label with a more sensible element -->
                    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                    <label>{{
                        $t('order.components.order_ticket.buttons.open_in')
                    }}</label>
                </div>
            </template>
            <div>
                <div
                    v-if="showTicketActions"
                    class="order-ticket__actions"
                >
                    <OrderTicketActions
                        :show-download-button="isDownloadable"
                        :download-link="ticket.download_link"
                        :ticket="ticket"
                    />
                </div>
                <div
                    v-else
                    class="order-ticket__divider"
                />
                <div
                    v-if="ticket.ticket.description"
                    class="order-ticket__description ot-text-small"
                >
                    <MarkdownRenderer>
                        {{ ticket.ticket.description }}
                    </MarkdownRenderer>
                </div>

                <div
                    v-if="shouldShowQrCode"
                    class="order-ticket__qr"
                >
                    <img
                        :src="qrImgUrl"
                        :alt="ticket.ticket_number"
                    >
                    <p class="ot-text-small">
                        {{ ticket.ticket_number }}
                    </p>
                </div>

                <OrderTicketProducts
                    v-if="products.length"
                    :products="products"
                />

                <OrderTicketMetadataForm
                    v-if="ticket.metadata && !ticket.is_complete"
                    ref="metadata_form"
                    :ticket="ticket"
                    :loading="loading"
                    @save="openConfirmMetaDataModal"
                />

                <div
                    v-else-if="ticket.metadata.length > 0 && ticket.is_complete"
                >
                    <OrderItemMetadata :metadata="ticket.metadata" />

                    <div
                        v-for="(product, i) in productsWithMetadata"
                        :key="product.guid"
                        class="order-ticket__metadata__product"
                    >
                        <!-- Todo: replace this label with a more sensible element -->
                        <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                        <label
                            class="
                                ot-text-tiny-strong
                                order-ticket__metadata__product__title
                            "
                        >
                            {{ product.product.name }} ({{ i + 1 }}/{{
                                productsWithMetadata.length
                            }})
                        </label>
                        <OrderItemMetadata :metadata="product.metadata" />
                    </div>
                </div>
            </div>
        </OrderItem>
        <OrderTicketStatus
            v-if="hasStatus"
            :ticket="ticket"
            :needs-personalization="needsPersonalization"
            :refund-in-progress="refundInProgress"
            :is-sealed="isSealed"
            :is-rendering="isRendering"
            :is-resold="isResold"
            :is-invalid="isInvalid"
            :is-cancelled="isCancelled"
            :is-locked="locked"
            :simplified-status="simplifiedStatus"
        />
    </div>
</template>

<script lang="ts">
import { StringMessage } from '@openticket/lib-order';
import type {
    IOrderMetaData,
    IOrderProduct,
    IOrderTicket,
} from '@openticket/lib-order';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { Log, send } from '@openticket/lib-log';
import QRCode from 'qrcode';
import OrderItem from '../OrderItem/OrderItem.vue';
import OrderItemMetadata from '../OrderItem/OrderItemMetadata.vue';
import OrderTicketMetadataForm from './OrderTicketMetadataForm.vue';
import OrderTicketProducts from './OrderTicketProducts.vue';
import OrderTicketStatus from './OrderTicketStatus.vue';
import { openModal } from '../../../../utils';
import ConfirmMetadataModal from '../ConfirmMetadata.vue';
import OrderTicketActions from './OrderTicketActions.vue';
import SimplifyStatus from '../../../../utils/simplify_status';
import { DistinctValidator, metadataGen, metadataGenForTicket } from '../../../../utils/sdk/metadata';
import MarkdownRenderer from '../../../../components/MarkdownRenderer.vue';

@Component({
    components: {
        MarkdownRenderer,
        OrderTicketActions,
        OrderItem,
        OrderItemMetadata,
        OrderTicketMetadataForm,
        OrderTicketProducts,
        OrderTicketStatus,
    },
})
export default class OrderTicket extends Vue {

    @Prop() ticket!: IOrderTicket;
    @Prop() locked!: boolean;
    @Prop() opened!: boolean;

    @Prop({ default: false, type: Boolean })
        showQrCode!: boolean;

    @Prop({ default: false, type: Boolean })
        hideDownloadAction!: boolean;

    @Prop() index!: number;
    @Prop() count!: number;
    @Prop() isLastIncomplete!: boolean;

    @Ref('metadata_form')
    private readonly refMetadataForm!: OrderTicketMetadataForm;

    loading = false;
    share = false;
    distinctValidator!: DistinctValidator;
    appleWalletLink: string | null = null;

    private qrImgUrl: string = '';

    public created(): void {
        Vue.observable(this.ticket);

        this.distinctValidator = new DistinctValidator(() => metadataGen(this.$order));

        this.generateQrImageUrl();
    }

    private async saveMetadata(): Promise<void> {
        try {
            this.loading = true;
            await this.$order.saveTicketMetadata(this.ticket);
            this.$emit('next');
            this.$notifications.success(
                this.$t(
                    'order.components.order_ticket.saved_personalization_message',
                ),
            );
        } catch (e) {
            // TODO
        } finally {
            this.loading = false;
        }
    }

    get personalizedTitle(): string | null {
        try {
            const candidates: string[][] = [
                [ 'fullname' ],
                [ 'first_name', 'last_name' ],
                [ 'first_name' ],
                [ 'last_name' ],
                [ 'email' ],
            ];

            for (const candidate of candidates) {
                const values: Array<
                    IOrderMetaData | undefined
                > = candidate.map((name: string) => this.ticket.metadata.find(
                    (metadata: IOrderMetaData) => metadata.item.name === name,
                ));

                if (
                    values.every(
                        (metadata: IOrderMetaData | undefined) => metadata
                            && typeof metadata.value === 'string'
                            && metadata.value.trim(),
                    )
                ) {
                    return (values as Array<{ value: string }>)
                        .map((metadata: { value: string }) => metadata.value.trim())
                        .join(' ');
                }
            }
        } catch (e) {
            return null;
        }

        return null;
    }

    get countTitle(): string | null {
        if (!this.index || this.count < 2) {
            return '';
        }
        return `(${this.index}/${this.count})`;
    }

    get products(): IOrderProduct[] {
        return this.ticket.products.filter((product: IOrderProduct) => product.is_optional);
    }

    get productsWithMetadata(): IOrderProduct[] {
        return this.ticket.products.filter(
            (product: IOrderProduct) => product.metadata && product.metadata.length,
        );
    }

    get shouldShowQrCode(): boolean {
        return (
            this.showQrCode && !this.ticket.invalidated_since && !this.isSealed
        );
    }

    generateQrImageUrl(): void {
        QRCode.toDataURL(this.ticket.ticket_number)
            .then((url: string) => {
                this.qrImgUrl = url;
            })
            .catch((err: Error) => {
                console.error(err);
                this.qrImgUrl = '';
            });
    }

    @Watch('opened')
    private onOpenedChange(): void {
        // Focus first element element in the metadata form
        if (this.opened && !this.ticket.is_complete) {
            setTimeout(() => {
                const elem = this.refMetadataForm.$el;
                const input = elem.querySelector('input');
                if (input) {
                    input.focus();
                }
            }, 300);
        }
    }

    public openConfirmMetaDataModal(): void {
        try {
            this.$order.validateMetadata([ ...metadataGenForTicket(this.ticket) ]);

            // DD-4E27 - It is possible to dead-lock late personalization if
            // distinct is applied on a question with limited possible answers.
            if (!this.distinctValidator.isValid()) {
                // Check if any metadata of THIS ticket is invalid, or others
                for (const metadata of metadataGenForTicket(this.ticket)) {
                    if (metadata.errors.length) {
                        console.warn('metadata distinction invalid', this.distinctValidator);
                        return;
                    }
                }
            }

            openModal({
                component: ConfirmMetadataModal,
                props: {
                    ticket: this.ticket,
                    countTitle: this.countTitle,
                    products: this.products,
                    isLastIncomplete: this.isLastIncomplete,
                },
                parent: this,
                events: {
                    save: () => {
                        void this.saveMetadata();
                    },
                },
            });
        } catch (e) {
            send(
                new StringMessage(
                    'osp.order_ticket.open_confirm_metadata_modal.failed_to_open',
                    'Failed to open confirm metadata modal',
                    { error: e },
                ),
                Log.Warn,
            );
        }
    }

    public get hasStatus(): boolean {
        return (
            (this.isSealed
                || this.needsPersonalization
                || this.refundInProgress
                || this.isResold
                || this.isRendering
                || this.isInvalid
                || this.isCancelled)
            && !(this.opened && this.hasContent)
        );
    }

    public get needsPersonalization(): boolean {
        return (
            !this.ticket.is_complete
            && this.ticket.ticket.late_personalization
            && !this.ticket.invalidated_since
        );
    }

    public get personalizationComplete(): boolean {
        return (
            this.ticket.is_complete
            && this.ticket.ticket.late_personalization
            && !this.ticket.invalidated_since
        );
    }

    public get refundInProgress(): boolean {
        return (
            !!this.ticket.invalidated_since
            && (this.ticket.invalidated_reason === 'return by visitor'
                || this.ticket.invalidated_reason === 'returned')
        );
    }

    public get isRendering(): boolean {
        return (
            !this.needsPersonalization
            && !this.ticket.computed_download_ready
            && !this.ticket.invalidated_reason
        );
    }

    public get isInvalid(): boolean {
        return !!this.ticket.invalidated_reason && !this.refundInProgress;
    }

    public get isResold(): boolean {
        return (
            !!this.ticket.invalidated_since
            && this.ticket.invalidated_reason === 'ticket is ticketswapped'
        );
    }

    public get hasContent(): boolean {
        return (
            (this.ticket.metadata.length > 0
                || this.products.length > 0
                || this.productsWithMetadata.length > 0
                || this.walletEnabled
                || this.isDownloadable)
            && !this.locked
        );
    }

    get isSealed(): boolean {
        return (
            !!this.ticket.retrievable_after
            && !this.ticket.download_link
            && !this.ticket.invalidated_since
            && !this.needsPersonalization
        );
    }

    get isCancelled(): boolean {
        return (
            this.ticket.invalidated_reason === 'ticket-cancelled'
            || this.ticket.invalidated_reason === 'product-cancelled'
        );
    }

    get isDownloadable(): boolean {
        return (
            !this.hideDownloadAction
            && !!this.ticket.download_link
            && !this.ticket.invalidated_since
            && (!this.$settings
                || !this.$settings.static.order.components.eventCard
                    .disableDownloadButton)
            && this.ticket.computed_download_ready
        );
    }

    get showTicketActions(): boolean {
        return (
            !this.isCancelled
            && !this.isSealed
            && !this.isResold
            && !this.isInvalid
            && !this.needsPersonalization
        );
    }

    get walletEnabled(): boolean {
        if (this.$order.data.status !== 'paid' || !this.$settings) {
            return false;
        }

        const appicEnabled = this.$settings.static.order.enableAppic
            && !!this.$whitelabel.order.appic_url;
        const closeEnabled = !!this.$settings.static.order.components.eventCard
            .closeUrl;
        const maveEnabled = this.$settings.static.order.enableMave
            && !!this.$whitelabel.order.mave_url;
        const partyPayEnabled = this.$settings.static.order.enablePartyPay
            && !!this.$whitelabel.order.party_pay_url;
        const ticketKeeperEnabled = this.$settings.static.order.enableTicketKeeper
            && !!this.$whitelabel.order.ticket_keeper_url;

        return !!(appicEnabled || closeEnabled || partyPayEnabled || maveEnabled || ticketKeeperEnabled);
    }

    get seatLabel(): string | null {
        let label = null;

        // Note, is_seated unexplicably seems to return false negatives.
        // This should be investigated, but commenting it out here for now
        // as it's only used as a minor performance optimisation.
        // if (this.$order.data.is_seated) {
        this.ticket.metadata.forEach((metadata) => {
            if (metadata.metadata.name === 'seat_label') {
                label = metadata.value;
            }
        });
        // }

        return label;
    }

    get simplifiedStatus(): 'paid' | 'pending' | 'cancelled' | null {
        return SimplifyStatus(this.$order.data.status);
    }

}
</script>

<style lang="scss" scoped>
.order-ticket {
    &__actions {
        padding: var(--ot-spacing-default);
        border-top: 2px solid var(--ot-shop-color-box-accent);
        &:not(:last-child) {
            border-bottom: 2px solid var(--ot-shop-color-box-accent);
            margin-bottom: 1rem;
        }
    }

    &__divider {
        margin: 0 var(--ot-spacing-default);
        margin-bottom: var(--ot-spacing-default);
        border-bottom: 2px solid var(--ot-shop-color-box-accent);
    }

    &__rendering {
        position: relative;
    }

    &__description {
        margin: 0 1.25rem 1.25rem;
    }

    &__qr {
        text-align: center;
        margin: 1rem 0;

        img {
            // Note: 192x192 (see qr url) with max-75%-scaling and css image-rendering: pixelated
            // seems to provide the best result.
            max-width: 75%;
            image-rendering: pixelated;
        }
    }

    &__metadata {
        &__product {
            padding-top: 1.25rem;
            border-top: 2px solid var(--ot-shop-color-box-accent);

            &__title {
                margin-left: 1.25rem;
                margin-bottom: 0.5rem;
                opacity: 0.5;
                display: block;
            }
        }
    }

    &__heading {
        &__sealed {
            display: flex;

            &__content {
                flex: 1;
                text-align: right;
                margin-right: 1rem;

                span {
                    display: block;
                    line-height: 1rem;
                    font-weight: 600;
                }
            }
        }
    }

    &.has-status {
        color: var(--ot-card-color);
        background-color: var(--ot-shop-color-box-dropdown);
        border-radius: var(--ot-card-border-radius);

        &.disabled {
            color: var(--ot-card-color);
            background-color: var(--ot-card-status-disabled);
        }

        &.actionable-by-visitor {
            color: var(--ot-input-border-color-focus-invert);
            background: var(--ot-input-border-color-focus);
        }

        &.in-progress {
            color: var(--ot-shop-color-white);
            background: var(--ot-shop-color-black);
        }
    }
}
</style>
