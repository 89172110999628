import markdownit from 'markdown-it';

/**
 * Wrapper function around markdown to do simple markdown parsing
 * @param input (markdown) string
 * @param allowHtml (bool) enable to keep existing html in tact
 * @return parsed markdown into html string
 */
export function parseMarkdown(input: string, allowHtml: boolean = false): string {
    const md = markdownit({
        // Todo: remove me when all HTML is removed from order status page
        html: allowHtml,
    });

    const defaultRender = md.renderer.rules.link_open
        || function rt(tokens, idx, options, env, self) {
            return self.renderToken(tokens, idx, options);
        };

    md.renderer.rules.link_open = function lo(tokens, idx, options, env, self) {
        // Add custom props to all links
        tokens[idx].attrSet('target', '_blank');
        tokens[idx].attrSet('rel', 'noreferrer noopener');
        return defaultRender(tokens, idx, options, env, self);
    };

    /**
     * Note: We have a css rule to not render the first child p as block, so you might be tempted to replace this render
     * with the markdown-it renderInline, but that doesn't convert properly if the markdown starts with a different block
     * than text, for example a list
     */
    return md.render(input);
}
