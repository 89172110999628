export interface MinimalMetadata {
    errors: string[];
    readonly is_complete?: boolean;
    readonly item: {
        readonly extra: string[];
        readonly guid: string;
    };
    readonly value: unknown;
}

export type ComparableValue = string | number | bigint | boolean | Date;

export const distinctErrorSlug = 'order.errors.validation.distinct' as const;

export class DistinctValidator {

    readonly metadata: () => Iterable<MinimalMetadata>;
    private seen: Map<string, Map<Exclude<ComparableValue, Date>, Set<MinimalMetadata>>> = new Map();

    constructor(metadata: () => Iterable<MinimalMetadata>) {
        this.metadata = metadata;
    }

    isValid(mutate: boolean = true): boolean {
        // DD-4E27 - It is possible to dead-lock late personalization if
        // distinct is applied on a question with limited possible answers.
        let isValid = true;

        this.seen.clear();

        for (const metadata of this.metadata()) {
            const [ duplicate, previous ] = this.hasDuplicate(metadata);

            if (!duplicate) {
                this.removeDuplicationError(metadata);

                continue;
            }

            isValid = false;

            if (mutate) {
                this.addDuplicationError(metadata);

                if (previous) {
                    // Only for the 'first' duplicate. See comment in hasDuplicate method.
                    this.addDuplicationError(previous);
                }
            }
        }

        return isValid;
    }

    addDuplicationError(metadata: MinimalMetadata): void {
        if (!metadata.errors.includes(distinctErrorSlug)) {
            metadata.errors.push(distinctErrorSlug);
        }
    }

    removeDuplicationError(metadata: MinimalMetadata): void {
        let i = metadata.errors.findIndex((v) => v === distinctErrorSlug);

        while (i >= 0) {
            metadata.errors.splice(i, 1);

            i = metadata.errors.findIndex((v) => v === distinctErrorSlug);
        }
    }

    private hasDuplicate(metadata: MinimalMetadata): [ status: boolean, previous: null | MinimalMetadata ] {
        if (!isMetadataWithDistinctRule(metadata) || !isComparable(metadata.value)) {
            // Non-comparable values are ignored for measuring distinction.
            // Other validation rules exist to force the validity or existence of values.
            // See the implementation for what is considered 'comparable'.
            // Most notable:
            // - An empty string is NOT considered comparable.
            // - bool(false) and number(0) are considered comparable.
            // - An array without truthy strings is NOT comparable.
            // - An array with at least one truthy string IS comparable.
            return [ false, null ];
        }

        const valueSet: Set<MinimalMetadata> = this.getOrAddValueSet(metadata.item.guid, metadata.value);

        if (valueSet.size === 1 && !valueSet.has(metadata)) {
            // One other metadata has already been added, which would not have been flagged as a duplicate yet.
            // Adding it to the result, so the error can be set if appropriate.
            const previous = [ ...valueSet.values() ][0];

            valueSet.add(metadata);

            return [ true, previous ];
        }

        valueSet.add(metadata);

        // For second (this one) and up iterations, the previous metadata need not be returned.
        // Every duplicate, except the first one can be handled solely by the result status.
        return [ valueSet.size > 1, null ];
    }

    private getOrAddValueSet(id: string, value: ComparableValue): Set<MinimalMetadata> {
        const seen: Map<Exclude<ComparableValue, Date>, Set<MinimalMetadata>> = this.getOrCreateSeenMap(id);

        const comparableKey = this.getComparableKey(value);

        let valueSet = seen.get(comparableKey);

        if (!valueSet) {
            valueSet = new Set();
            seen.set(comparableKey, valueSet);
        }

        return valueSet;
    }

    private getComparableKey(value: ComparableValue): Exclude<ComparableValue, Date> {
        let lookupValue: Exclude<ComparableValue, Date>;

        if (typeof value === 'object') {
            lookupValue = (value satisfies Date).getTime();
        } else if (typeof value === 'string') {
            lookupValue = value.toLowerCase().trim();
        } else {
            lookupValue = value;
        }

        return lookupValue;
    }

    private getOrCreateSeenMap(id: string): Map<Exclude<ComparableValue, Date>, Set<MinimalMetadata>> {
        let seen = this.seen.get(id);

        if (!seen) {
            seen = new Map<Exclude<ComparableValue, Date>, Set<MinimalMetadata>>();
            this.seen.set(id, seen);
        }

        return seen;
    }

}

export function isMetadataWithDistinctRule(metadata: MinimalMetadata): boolean {
    if (!metadata.item.extra || !Array.isArray(metadata.item.extra)) {
        return false;
    }

    return metadata.item.extra.some((rule: string) => rule === 'distinct' || rule.startsWith('distinct:'));
}

export function isComparable(value: unknown): value is (bigint | boolean | number | string | Date) {
    // DD-4E26 - Arrays, falsy strings and NaN dates are ignored.
    switch (typeof value) {
        case 'bigint':
        case 'boolean':
        case 'number':
            return true;
        case 'object':
            if (value instanceof Date) {
                return !Number.isNaN(value.getTime());
            }

            return false;
        case 'string':
            return !!value;
        default:
            return false;
    }
}
