/**
 * Note: This file has been copied over from Shop-SDK.
 *
 * We might move this to lib-order in the future.
 */

import {
    type IOrderMetaData,
    OrderError,
} from '@openticket/lib-order';
import { Validator } from './validator';
import { MetadataType, type ValidatableMetadata } from './types';

const availableLocales: string[] = [
    'ar',
    'az',
    'be',
    'bg',
    'bs',
    'ca',
    'cs',
    'cy',
    'da',
    'de',
    'el',
    'en',
    'es',
    'et',
    'eu',
    'fa',
    'fi',
    'fr',
    'hr',
    'hu',
    'id',
    'it',
    'ja',
    'ka',
    'ko',
    'lt',
    'lv',
    'mk',
    'mn',
    'ms',
    'nl',
    'pl',
    'pt',
    'ro',
    'ru',
    'se',
    'sl',
    'sq',
    'sr',
    'sv',
    'tr',
    'ua',
    'uk',
    'vi',
    'zh',
];

/**
 *  The validator defaults to english, but on language change, the validator should also follow.
 *  Note however, any already generated errors will nog change. Only new generated errors will be in the new language.
 */
export function setValidationLang(locale: string): void {
    if ([ 'nb_NO', 'pt_BR', 'zh_TW' ].includes(locale)) {
        Validator.useLang(locale);

        return;
    }

    const lang: string = locale.slice(0, 2);

    if (locale.length >= 2 && availableLocales.includes(lang)) {
        Validator.useLang(lang);

        return;
    }

    Validator.useLang('en');
}

/**
 *  Validate metadata will return an error of error messages if its value does not pass the (extra) rules.
 *
 *  By default, when a validation rule fails its error will be added to the errors arrays of the metadata.
 *
 *  @param metadata - The metadata instance to validate
 *  @param mutateErrors - When set to false, any errors will not be written to the metadata errors array
 */
export function validateMetadata(
    metadata: ValidatableMetadata,
    mutateErrors = true,
): string[] {
    if (!metadata) {
        throw new OrderError(
            'Failed to validate metadata -> no cart metadata provided',
        );
    }

    // noinspection SuspiciousTypeOfGuard
    if (
        !metadata.item
        || !metadata.item.name
        || typeof metadata.item.name !== 'string'
    ) {
        throw new OrderError(
            'Failed to validate metadata -> invalid metadata item',
        );
    }

    // noinspection SuspiciousTypeOfGuard
    if (
        !Object.prototype.hasOwnProperty.call(metadata.item, 'extra')
        || !(
            Array.isArray(metadata.item.extra)
            || typeof metadata.item.extra === 'string'
        )
    ) {
        throw new OrderError(
            'Failed to validate metadata -> invalid metadata rules',
        );
    }

    // TODO Once the _rules property is ALWAYS set on the metadata.item,
    //  the reliance on the extra string can be completely replaced with
    //  the _rules property

    if (!Object.prototype.hasOwnProperty.call(metadata, 'value')) {
        throw new OrderError(
            'Failed to validate metadata -> value property not present on cart metadata',
        );
    }

    const ruleStrOrRules: string | string[] = metadata.item.extra as
        | string
        | string[];

    let rules: string[] = typeof ruleStrOrRules === 'string'
        ? ruleStrOrRules.split('|')
        : ruleStrOrRules;

    if (metadata.item.type === MetadataType.EnumOther) {
        rules = rules.filter((rule: string) => !rule.startsWith('in:') && rule !== 'distinct' && !rule.startsWith('distinct:'));
    }

    const name: string = metadata.item.translateName || metadata.item.name;

    const validator: Validator.Validator<ValidatableMetadata> = new Validator(
        metadata,
        { value: rules },
    );

    validator.setAttributeNames({ value: name.toString().toLowerCase() });

    validator.check();

    const errors = validator.errors.get('value');

    if (mutateErrors) {
        // Empty arrays without breaking references
        metadata.errors.splice(0, metadata.errors.length);

        // Add the error objects to the _errors list
        metadata.errors.push(...errors);
    }

    return errors;
}

export interface IOrderProductForMetadataGen {
    metadata: IOrderMetaData[];
}

export interface IOrderTicketForMetadataGen {
    metadata: IOrderMetaData[];
    products: IOrderProductForMetadataGen[];
}

export interface OrderClientForMetadataGen {
    data: {
        tickets: IOrderTicketForMetadataGen[];
        products?: IOrderProductForMetadataGen[];
        metadata: IOrderMetaData[];
    };
}

/**
 *  Generate a metadata iterable, in the form of a generator.
 *
 *  By default, this yields every ticket and product metadata.
 *
 *  @param order - The order client
 *  @param metadataId - When set to a metadata's id, the resulting iterable will only yield metadata of that type.
 */
export function* metadataGen(order: OrderClientForMetadataGen, metadataId?: string): Generator<IOrderMetaData> {
    for (const ticket of order.data.tickets) {
        yield* metadataGenForTicket(ticket, metadataId);
    }
}

export function* metadataGenForTicket(ticket: IOrderTicketForMetadataGen, metadataId?: string): Generator<IOrderMetaData> {
    for (const metadata of ticket.metadata) {
        if (!metadataId || metadata.item.guid === metadataId) {
            yield metadata;
        }
    }

    for (const product of ticket.products) {
        for (const metadata of product.metadata) {
            if (!metadataId || metadata.item.guid === metadataId) {
                yield metadata;
            }
        }
    }
}
