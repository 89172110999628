<template>
    <div
        v-if="show"
        class="ot-card has-no-padding green-product"
        role="button"
        tabindex="0"
        @click="fireConfetti"
        @keydown.enter="fireConfetti"
        @keydown.space="fireConfetti"
    >
        <CardSection
            class="green-product__header"
            separator
        >
            <div class="green-product__header__content">
                <a href="https://treesforall.nl/en/">
                    <img
                        alt="Trees for All"
                        src="../../../../assets/images/upgrades/logo-trees-for-all.svg"
                        class="green-product__header__content__logo"
                    >
                </a>

                <h3 class="green-product__header__content__title">
                    {{
                        $t(
                            'order.components.upgrades.green_product.header.content.title'
                        )
                    }}
                </h3>

                <span
                    class="green-product__header__content__subtitle ot-text-tiny"
                >{{
                    $t(
                        'order.components.upgrades.green_product.header.content.subtitle'
                    )
                }}</span>
            </div>
            <div class="green-product__header__image" />
        </CardSection>

        <CardSection
            class="green-product__content"
            separator
        >
            <img
                ref="green-tree"
                class="green-product__content__icon"
                src="../../../../assets/images/icons/tree.png"
                alt="Tree"
            >

            <h3 class="green-product__content__title">
                {{
                    $t('order.components.upgrades.green_product.content.title')
                }}
            </h3>

            <p class="green-product__content__text ot-text-small">
                <MarkdownRenderer allow-html>
                    {{ $t('order.components.upgrades.green_product.content.text') }}
                </MarkdownRenderer>
            </p>

            <a href="https://treesforall.nl/en/">
                <img
                    alt="Trees for All"
                    src="../../../../assets/images/upgrades/logo-trees-for-all.svg"
                    class="green-product__content__logo"
                >
            </a>
        </CardSection>

        <div class="confetti">
            <canvas
                ref="canvas"
                class="confetti__canvas"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Confetti from 'canvas-confetti';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
import type { IOrderGlobalProduct } from '@openticket/lib-order';
import CardHeader from '../CardHeader.vue';
import CardSection from '../CardSection.vue';
import MarkdownRenderer from '../../../../components/MarkdownRenderer.vue';

const productClass = 'Co2Compensate';

@Component({
    components: { MarkdownRenderer, CardSection, CardHeader },
})
export default class GreenProduct extends Vue {

    @Ref('confetti')
        confetti!: HTMLCanvasElement;

    @Ref('green-tree')
        greenTree!: Element;

    observer: null | IntersectionObserver = null;
    confettiFn: null | Confetti.CreateTypes = null;

    mounted(): void {
        this.unobserve();

        this.initObserver();
    }

    beforeDestroy(): void {
        this.unobserve();
    }

    unobserve(): void {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    handleIntersection(entries: IntersectionObserverEntry[]): void {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
                this.unobserve();

                this.fireConfetti();
            }
        });
    }

    fireConfetti(): void {
        if (this.confettiFn) {
            const boundingRect: DOMRect = this.greenTree.getBoundingClientRect();
            const vh: number = Math.max(
                document.documentElement.clientHeight || 0,
                window.innerHeight || 0,
            );

            void this.confettiFn({
                colors: [ '#00c805' ],
                spread: 360,
                startVelocity: 30,
                particleCount: 200,
                origin: {
                    x: 0.5,
                    y: (boundingRect.top + boundingRect.height / 2) / vh,
                },
            });
        }
    }

    get show(): boolean {
        return !!this.$order.data.global_products.find(
            (product: IOrderGlobalProduct) => product.product.class === productClass,
        );
    }

    @Watch('show')
    onShowChange(): void {
        this.initObserver();
    }

    initObserver(): void {
        if (this.observer || !this.show) {
            return;
        }

        // IntersectionObserver is a relatively new feature,
        // Confetti is not deemed critical, therefore, just ignore if it doesn't exist.
        if ('IntersectionObserver' in window) {
            this.observer = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => this.handleIntersection(entries),
                { threshold: 1.0 },
            );

            this.confettiFn = Confetti.create(this.confetti, {
                resize: true,
                useWorker: true,
            });

            this.observer.observe(this.greenTree);
        }
    }

}
</script>

<style lang="scss" scoped>
.green-product {
    margin-bottom: 1rem;
    overflow-x: hidden;
    border: 1px solid #00c805;

    &__header {
        box-sizing: border-box;
        display: flex;
        padding: 0;

        * {
            box-sizing: border-box;
        }

        &__content {
            width: 50%;
            padding: 1.25rem;

            &__subtitle {
                word-break: break-word;
            }

            &__logo {
                height: 1.25rem;
            }
        }

        &__image {
            display: flex;
            width: 50%;
            background-image: url('../../../../assets/images/upgrades/green-trees.png');
            background-repeat: no-repeat;
            background-position: left bottom;
            background-size: cover;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        padding: 1.5rem 2.5rem;

        &__icon {
            height: 2rem;
        }

        &__logo {
            height: 1.75rem;
        }
    }

    .confetti {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 999;

        &__canvas {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
